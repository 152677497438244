// horisontal category list plugin
.underline::after{
    margin-top: 1.07143rem;
    width: 5rem;
    border-top-width: 1px;
    content: "";
    display: inline-block;
    border-top-style: solid;
    border-color: $primary!important;
    box-sizing: border-box;
}

.list_horizontal-list .page-item:first-child{
  margin-left: auto !important;
}

.list_horizontal-item:hover{
    z-index: 10;
    -webkit-box-shadow: 4px 6px 10px 0 rgba(0,0,0,.12)!important;
    box-shadow: 4px 6px 10px 0 rgba(0,0,0,.12)!important;
    cursor: pointer;
    text-decoration: none;
    border-color: $gray-600!important;
}
// anons plugin
.underline > a:hover {
      text-decoration: none;
      .font-weight-light{
        font-weight: 400!important;
      }
    }

.anons-item {
    cursor: pointer;
}

.anons-item > img {
    vertical-align: middle;
    border-style: none;
    width: 100%;
    max-height: 270px;
    transition: all .2s ease-out 0s;
}

.anons-item:hover > img {
    transform: scale(1.05);
}

.date-tag{
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15) !important;
    position: absolute;
    right: -8px;
    top: 15px;
    z-index: 10;
}
// category
.block-item .card{
  border: none;
}

.card-title >h4{
  font-weight: 500;
}
.block-item .card::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 30px;
    content: '';
    -webkit-box-shadow: inset 0 -25px 39px -5px #f5f5f5;
    box-shadow: inset 0 -25px 39px -5px #f5f5f5;
}

.card-block >p{
  margin-bottom: 0.3rem;
}


// icon link plugin
.services {
  transition: all .2s ease-out 0s;
  min-height: 120px;

  &:hover {
    z-index: 10;
    -webkit-box-shadow: 4px 6px 10px 0 rgba(0, 0, 0, .12) !important;
    box-shadow: 4px 6px 10px 0 rgba(0, 0, 0, .12) !important;
    cursor: pointer;
    text-decoration: none;
    border-color: #5f7d78 !important;
    color: #5f7d78 !important;
  }

  a {
    text-decoration: none;
  }
  .services-text {
    line-height: 1;
    font-size: 3rem;
    color: $primary;
    float: left;
  }

  .services-icon {
    font-size: 4rem;
    font-weight: 600 !important;
    display: inline-block;
    float: right;

    .ico:before {
      font-weight: 600 !important;
    }

  }

}

// Header

.header {
  background-color: $secondary;
}

.header-info {

  .container {
    display: flex;
  }

  .right-col {
    width: 100%;
  }

  .brand {
    h1 {
      margin: 0;
      font-size: 1.6rem;
      text-transform: uppercase;
    }

    a {
      transition: ease-in-out color .15s;

      &:hover, &:focus {
        text-decoration: none;
        color: $link-hover-color;
      }
    }
  }

  .logo img {
    max-width: 35px;
    margin-right: 0;
  }

  .spec-icons {

    ul li {
      float: left;
    }

    .search {
      font-size: 0;
      line-height: 1;
      margin-right: 25px;
    }
    .ico-glass {
      background: url('../../img/ico-glass.png') no-repeat;
      width: 50px;
      height: 22px;
      display: inline-block;
    }

    .ico-search {
      background: url('../../img/ico-search.png') no-repeat;
      width: 22px;
      height: 22px;
      display: inline-block;
    }
  }

  .social-network {
    list-style: none;
    display: inline-block;
    padding: 0;
  }

  .social-network li {
    display: inline-block;
    margin: 0 5px;
  }
}

.header-menu {
  box-shadow: 0 0 0;
  margin-bottom: 0;

  .navbar{
    padding: 0.5rem 0rem;
  }
  .navbar-nav {
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
  }

  .nav-link {
    font-size: 1rem;
    display: block;
    padding: .5em 0;
    text-transform: uppercase;
    transition: ease-in-out color .15s;
  }

  .navbar-light .navbar-nav .nav-link {
    color: $primary;
    font-weight: 500;

    &:hover, &:focus {
      color: $link-hover-color;
    }
  }

  .navbar-light .navbar-nav .active .nav-link {
    color: $link-hover-color;
    cursor: default;
  }

  .dropdown-menu {
    border-radius: 0;
  }
}

.menu-shadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    left:100%;
    margin-top:-6px;
    margin-left:-1px;
    -webkit-border-radius:0 6px 6px 6px;
    -moz-border-radius:0 6px 6px 6px;
    border-radius:0 6px 6px 6px;
}
.dropdown-submenu:hover>.dropdown-menu {
    display:block;
}
.dropdown-submenu>a:after {
    display:block;
    content:" ";
    float:right;
    width:0;
    height:0;
    border-color:transparent;
    border-style:solid;
    border-width:5px 0 5px 5px;
    border-left-color:#cccccc;
    margin-top:10px;
    margin-right:-10px;
}
.dropdown-submenu:hover>a:after {
    border-left-color:#ffffff;
}
.dropdown-submenu.pull-left {
    float:none;
}
.dropdown-submenu.pull-left>.dropdown-menu {
    left:-100%;
    margin-left:10px;
    -webkit-border-radius:6px 0 6px 6px;
    -moz-border-radius:6px 0 6px 6px;
    border-radius:6px 0 6px 6px;
}

.list-group.vertical {
  &:first-child {
    border-top: 3px solid $blue;
  }
}

.list-group.horizontal {
  display: inline-block;
  padding-top: 20px;
  border-top: 3px solid $blue;
  width: 100%;

  .list-group-item {
    margin-bottom: 15px;
    float: left;
    margin-right: 20px;
  }
}
.list-group.vertical:first-child {
  border-top: 3px solid #000;
}
.list-group.vertical {
  .list-group-item {
    margin-bottom: 1px;
  }
}

// Content

.page{
  margin-top: 2.2rem;
}

.page.index-page{
  margin-top: 6.2rem;
}

.top-news {

  .nav-tabs {
    border-bottom: 0;
    margin-bottom: 20px;

    .nav-item {
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }
    }

    .nav-link {
      font-size: 1.45rem;
      color: $link-color;
      border: 0;
      border-bottom: 1px dashed #999999;
      padding: 0;

      &:hover {
        color: $primary;
        border: 0;
        border-bottom: 1px dashed $primary;
      }
    }

    .nav-link.active {
      color: #111;
      border-bottom: 0;
    }
  }

  .tab-content {
    .left-col {
      min-width: 50%;
      padding-right: 3%;
    }

    .right-col {

      .item {
        padding: 10px;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background: $secondary;

          a {
            color: $link-color;
          }
        }
      }

      .item.active {
        background: $secondary;

        h4 {
          font-weight: normal;
        }
      }

      .date {
        font-size: 0.75rem;
        color: #999999;
      }

      .item-title {
        h4 {
          font-size: 1rem;
          color: #111;
          line-height: 1.4;

          a {
            color: #111;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    >.active {
      display: flex;
    }
  }
}



.leader {
  position: relative;
  padding-top: 53px;

  .leader-name {
    bottom: 0px;
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    padding: 10px;
    color: white;
    transition: all 0.35s ease 0s;
    overflow: hidden;

    .position {
      font-size: 0.85rem;
    }

    .title {
      font-size: 1.15rem;
    }
  }
  .leader-name:hover {
    bottom: 35px;
    max-height: 192px;
  }

  .call-btn {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 35px;

    .btn {
      font-weight: bold;
      padding: 10px;
    }
  }
}

.service-and-docs {
  background: $gray-100;
  padding-top: 30px;
}
.services {
    h2 {
      margin-top: 10px;
      margin-bottom: 25px;
    }

    .row {
      margin-right: 0;
      margin-left: 0;
    }

    .item, .spec-item {
      height: 70px;
      display: flex;
      border: 1px solid #efefef;
      background-color: #fff;
      -webkit-transition: all .3s ease 0s;
      transition: all .3s ease 0s;
      align-items: center;

      &:hover {
        z-index: 10;
        box-shadow: 4px 6px 10px 0 rgba(0, 0, 0, 0.12);
        cursor: pointer;
        text-decoration: none;

        .category-title {
          color: $primary;
        }
      }

      .category-title, .spec-category-title {
        position: relative;
        line-height: 1;
        font-size: 1.15rem;
        color: #5d5d5d;
        overflow: hidden;
      }
      .spec-category-title{
        color: #111;
      }
    }
  .spec-item{
    height: 98px;
  }

    .item.last {
      background-color: $primary;
      color: white;

      .category-title {
        color: white;
      }
    }
  }
.list-list {
    h2 {
      margin-top: 35px;
      margin-bottom: 25px;
    }

    .docs-inner {
      height: 400px;
      overflow: hidden;
      overflow-y: scroll;
    }

    .list-item {
      background: white;
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .icon-block {
      margin-right: 10px;
      justify-content: center;
      display: flex;
      min-width: 120px;
      text-align: center;
      padding-top: 5px;
      position: relative;
      align-items: baseline;

      .doc-number {
        color: #999;
        padding: 1px 5px;
        font-size: 0.73rem;
        position: absolute;
        top: 30px;
        left: 50%;
        margin-left: -25%;
        max-width: 60px;
        background: #eeeeee;
        word-wrap: break-word;
      }
    }


    .title-block {
      padding-right: 50px;
      padding-left: 20px;
      width: 100%;

      h4.doc-title {
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.4;

        a {
          color: #555;

          &:hover {
            color: $primary;
          }
        }
      }

      .date {
        font-size: 0.73rem;
        color: #999999;
      }
    }

    .link-block {
      min-width: 100px;

      .doc-info {
        font-size: 0.73rem;
        color: #999999;
      }
    }
  }

.banner {
  padding-top: 20px;
}

.partners {
  padding-top: 20px;
  padding-bottom: 20px;

  .list-partners {
    position: relative;

    .prev-arr {
      margin-top: -20px;
      line-height: 1;
      font-size: 40px;
      color: #111;
      position: absolute;
      top: 50%;
      left: -25px;

      &:hover {
        color: transparent;
      }
    }

    .next-arr {
      margin-top: -20px;
      line-height: 1;
      font-size: 40px;
      color: #111;
      position: absolute;
      top: 50%;
      right: -25px;

      &:hover {
        color: $primary;
      }
    }
  }

  ul {
    display: flex;
    justify-content: space-between;

    li {
      margin-right: 30px;
      text-align: center;
      width: 25%;

      a {
        color: #111;

        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .img-block {
    margin-bottom: 20px;
    padding-right: 15px;
  }

  .title-block {
    border-right: 0 solid #ccc;
    padding-right: 15px;

    .source-link {
      text-transform: uppercase;
      font-size: 12px;
    }

    .desc-link {
      text-transform: uppercase;
      font-size: 12px;
      color: #888888 !important;
    }
  }
}

.footer {
  background: $gray-200;

  .top-footer {
    border-top: 1px solid $gray-400; //border
    font-size: 0.95rem;
    padding-bottom: 30px;

    > .container {
      border-top: 0px solid $gray-400;
    }

    .item {
      a {
        color: $primary;
        font-weight: 400;

        &:hover {
          color: $primary;
        }
      }

      h4 {
        font-size: 1.1rem;
        color: $primary;
        line-height: 1.2;
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-weight: bold;

      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        line-height: 1.2;

        li {
          margin-bottom: 12px;
        }
      }
    }
  }

  .middle-footer {
    border-top: 1px solid #e0e0e0;
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 0.9rem;
    color: #555;

    .address {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    p {
      margin-top: 0;
      margin-bottom: 10px;
    }

    .phone {
      font-weight: bold;
    }
  }
}

.block-list {
  .list-items {
    display: inline-block;
    width: 100%;
  }

  .block-item {
    display: inline-block;
    width: 48%;
    margin-right: 1.5%;
    margin-bottom: 2%;
    word-wrap: break-word;

    a {
      color: #111111;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }

    .h4, h4 {
      font-size: 1.13rem;
      line-height: 1.3;
      letter-spacing: 0;
    }

    .card-block {
      position: relative;
    }

    .card-title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      overflow: hidden;
      max-height: 76px;
      min-height: 76px;
      transition-property: max-height, background-color;
      text-transform: inherit;
      font-weight: inherit;
      transition: all 0.35s ease 0s;
      margin: 0;
      padding: 7px 20px;
    }

    .card:hover .card-title {
      max-height: 192px;
      background-color: #fff;
    }

    .ribbons {
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 8px;
      left: -6px;
      z-index: 10;
      padding-left: 0;
      margin-left: 0;
      list-style: none;

      .ribbon-inner {
        position: relative;
        margin-bottom: 7px;

        .ribbon {
          background-color: $primary;
          color: #fff;
          padding: 6px 16px;
          display: inline-block;
          font-size: 0.7rem;
          position: relative;
          right: 0;
          white-space: nowrap;
          line-height: 15px;
          text-transform: uppercase;
          font-weight: inherit;

          &:after {
            left: 0;
            top: 100%;
            border-width: 3px 3px;
            border-style: solid;
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            border-color: darken($primary, 10%) darken($primary, 10%) transparent transparent;
          }
        }
      }
    }
  }
}

.news-description {
  .feature-image {
    margin-top: 30px;
    margin-bottom: 30px;
    display: inline-block;
  }

  .news-date {
    color: #999;
    font-size: 0.8rem;
  }

  .news-category {
    display: inline-block;
    font-size: 0.8rem;
    color: #999999;
  }
}

.more-news {
  .bordered-title {
    border-top: 3px solid $primary;
    padding-top: 10px;
    font-size: 1.13rem;
  }

  .publication {
    background: #f9f9f9;
    padding: 15px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    .h4, h4 {
      font-weight: normal;
      font-size: 1.1rem;
      line-height: 1.3;

      a {
        color: #111111;

        &:hover {
          color: $primary;
        }
      }
    }

    .date {
      color: #999999;
      font-size: 0.8rem;
    }
  }
}

.calendar-month {
  background: #efeff1;
  padding: 12px;

  .calendar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: #111111;

      &:hover {
        color: $primary;
      }
    }
  }

  table {
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 3;

    th {
      text-align: center;
      font-size: 1rem;
      padding: 15px 0;
      font-weight: bold;
    }

    td {
      font-size: 1rem;
      font-weight: 400;
    }

    td span, td a {
      width: 31px;
      height: 31px;
      display: inline-block;
      text-align: center;
      line-height: 31px;
      border: none;
      -webkit-transition: all 0.2s ease-in-out 0s;
      -khtml-transition: all 0.2s ease-in-out 0s;
      -moz-transition: all 0.2s ease-in-out 0s;
      -ms-transition: all 0.2s ease-in-out 0s;
      -o-transition: all 0.2s ease-in-out 0s;
      transition: all 0.2s ease-in-out 0s;
    }

    td a {
      background: $secondary;

      &:hover {
        color: #fff;
        background: $primary;
        text-decoration: none;
      }
    }

    td.NewsCalToday span, td.NewsCalToday a {
      background: $primary;
      color: white;
    }

    td.NewsCalOtherMonth {
      color: #999999;
    }
  }
}

.filter {
  .h4, h4 {
    font-size: 1.13rem;
  }
  .filter-block {
     list-style-type: none;
  }

  .filter-list {
    list-style-type: none;
    padding-left: 25px;
  }
}

.checkbox {
  padding-left: 0; }
.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 5px; }
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 3px;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 3px;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 0.8rem;
  color: #555555; }
.checkbox input[type="checkbox"] {
  opacity: 0; display: none; }
.checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }
.checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\f00c"; }
.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65; }
.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed; }
.checkbox.checkbox-circle label::before {
  border-radius: 50%; }
.checkbox.checkbox-inline {
  margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca; }
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: $primary;
  border-color: $primary; }
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de; }
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c; }
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff; }

.radio {
  padding-left: 0; }
.radio label {
  display: inline-block;
  position: relative;
  padding-left: 5px; }
.radio label::before {
  top: 3px;
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out; }
.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 6px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #555555;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
.radio input[type="radio"] {
  opacity: 0; display: none; }
.radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }
.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1); }
.radio input[type="radio"]:disabled + label {
  opacity: 0.65; }
.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed; }
.radio.radio-inline {
  margin-top: 0; }

.radio-primary input[type="radio"] + label::after {
  background-color: #428bca; }
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #428bca; }
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #428bca; }

.radio-danger input[type="radio"] + label::after {
  background-color: $primary; }
.radio-danger input[type="radio"]:checked + label::before {
  border-color: $primary; }
.radio-danger input[type="radio"]:checked + label::after {
  background-color: $primary; }

.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de; }
.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de; }
.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de; }

.radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e; }
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e; }
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e; }

.radio-success input[type="radio"] + label::after {
  background-color: #5cb85c; }
.radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c; }
.radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c; }



.card {
  border-radius: 0;
}

.pagination {
  border-radius: 0;
}

.page-item:first-child .page-link {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.page-item:last-child .page-link {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

// ПОИСК
.style-hide{
  display: none;
  visibility: hidden;
}
.search-full {
  padding: 20px;
  box-shadow: 0 10px 28px -10px rgba(0, 0, 0, 0.2);
  letter-spacing: 1px;
  min-height: 50px;
  white-space: nowrap;
  width: 100%;
  background: white;

  .searchInputBox {
    background: #eee;

    .inner-search {
      position: relative;
      padding-right: 40px;

      .search-input {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border: 0 none;
        color: #111;
        font-size: 1.35rem;
        line-height: 1.2;
        margin: 0;
        outline: medium none;
        text-align: left;
        padding: 13px 10px 13px 15px;
        width: 100%;
        max-width: 100%;
        background: #eee;

        &::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.6);
        }
        &::-moz-input-placeholder {
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .ac-results {
        top: inherit;
        /*bottom: -22px;
*/
        width: 100%;
        left: inherit;
        right: -11px;
        border-top: 0 none;
        box-shadow: 0 2px 5px rgba(0,0,0,0.09);
        margin-left: 20px;
        text-align: left;
        display: block;
        overflow: hidden;
      }
      .search-btn {
        top: 12px;
        right: 50px;
        cursor: pointer;
        position: absolute;

        &:hover {
          color: $primary;
        }

        i:hover {
          color: $primary;
        }
      }
    }
  }

  .search-close {
    z-index: 100;
    position: relative;
    background: transparent;
    cursor: pointer;
    display: table;
    float: right;
    font-size: 1.6rem;
    height: 51px;
    padding: 0 15px;

    i {
      display: table-cell;
      vertical-align: middle;
      transition: all 0.5s ease 0s;
      -moz-transition: all 0.5s ease 0s;
      -webkit-transition: all 0.5s ease 0s;
      -ms-transition: all 0.5s ease 0s;
      -o-transition: all 0.5s ease 0s;
      color: #ddd;

      &:hover {
        color: $primary;
      }
    }

    &:hover {
      text-decoration: none;

      i {
        color: $primary;
        transform: scale(1.15) rotate(-180deg);
        -webkit-transform: scale(1.1) rotate(-180deg);
        -moz-transform: scale(1.1) rotate(-180deg);
        -ms-transform: scale(1.1) rotate(-180deg);
        -o-transform: scale(1.1) rotate(-180deg);
      }
    }
  }

  &.active {
    display: block;
    z-index: 9999;
  }

}


/* ADAPTED VERSION */

.adapted {
  .search-full {
    background: #000000;

    .search-close {
      background: #000000;
      color: #ffffff;
    }
  }

  .searchInputBox .inner-search .search-input {
    color: #fff;
    background: #000000;
  }

  .searchInputBox .inner-search .search-btn {
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }
}

// СТРУКТУРА
.structure-list {

  .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// MEDIA RULES

// Medium devices (tablets, 768px and up)
@media (max-width: 486px){

  .fixed-top {
    position: unset !important;
  }

  .h1, h1 {
    font-size: 0.7rem !important;
    font-weight: 400;
  }
}

@media (min-width: 487px) and (max-width: 767px) {

  .fixed-top {
        position: unset!important;
  }

  .h1, h1 {
    font-size: 1.133rem!important;
    font-weight: 400;
  }

  .h3, h3 {
    font-size: 1.33rem;
  }

  .spec-icons {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 0;
  }

  .header-info > .container {
    flex-wrap: wrap;
  }

  .header-info .bottom-header {
    flex-wrap: wrap;
  }

  .header-menu .mainmenu {
    padding-left: 0;
    padding-right: 0;
  }

  .top-news .nav-tabs {
    flex-wrap: wrap;
  }

  .list-list .item {
    flex-wrap: wrap;
  }

  .list-list .icon-block {
    margin-bottom: 10px;
    min-width: initial;
  }

  .partners {
    .img-block {
      margin-bottom: 20px;
      padding-right: 0;
    }

    .title-block {
      border-right: 0;
      padding-right: 0;
    }

    ul {
      flex-wrap: wrap;

      li {
        margin-right: 0;
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  .partners .list-partners .prev-arr {
    left: 0;
  }

  .partners .list-partners .next-arr {
    right: 0;
  }

  .banner {
    display: none;
  }

  .invisible {
    display: none;
  }

  .block-list .block-item {
    width: 100%;
    margin-right: 0;
  }

  .calendar-month {
    margin-top: 20px;
  }

  .card-block .media {
    flex-wrap: wrap;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {

  .h1, h1 {
    font-size: 1.6rem!important;
    font-weight: 400;
  }

  .header-info > .container {
    display: flex;
    flex-wrap: wrap;
  }

  .spec-icons {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mainmenu {
    padding: .5rem 0;
  }

  .navbar-toggler {
    align-self: center;
  }

  .partners {
    .title-block {
      border-right: 0;
      padding-right: 0;
    }

    .img-block {
      margin-bottom: 20px;
      padding-right: 0;
    }

    ul li {
      margin-right: 1%;
      text-align: center;
      width: 49%;
      margin-bottom: 30px;
    }
  }

  .block-list .block-item {
    width: 48%;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {

  .top-news .tab-content > .active {
    flex-wrap: wrap;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .h1, h1 {
    font-size: 1.6rem!important;
    font-weight: 400;
  }

  .block-list .block-item {
    width: 31%;
  }
}
.img-fluid{
  margin: auto;
}
.side_wraper {
  word-wrap: break-word;
}
.more-by-tags-publications{
  .list-group-item{
    padding:0;
  }
}

.title-header .pagination-block{
  margin-top: 0;
  margin-bottom: 0;
}

.index-page h3 {
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #111111;
}

.index-page a > h3{
  color: #111111;

  &:hover, &:focus {
    color: $primary;
    text-decoration: none;
    text-decoration-color: $primary !important;
  }

  &:focus {
    text-decoration: none;
  }
}
/*
==================== Sitemap Page Styles ====================
*/
.sitemap {
    padding: 14px 0 30px;
}

.sitemap span a:before {
    content: "\f015";
    display: inline-block;
    font-family: "FontAwesome";
    font-size: 20px;
}

.sitemap ul.tree {
    margin-bottom: 0;
}

.sitemap ul, .sitemap li {
    list-style: none;
    margin-left: -37px;
}

.sitemap ul.tree {
    padding-left: 34px;
    margin-left: -37px;
}

.sitemap ul.tree li {
    margin: 0 0 0 2px;
    padding: 5px 0 0 22px;
    border-left: 1px solid #e3d4c1;
    background: url(../img/sitemap-horizontal.png) no-repeat left 15px transparent;
}

.sitemap ul.tree > li {
    margin: 0 0 0 11px;
}

.sitemap ul li:last-child {
    border: medium none;
    background: url(../img/sitemap-last.png) no-repeat 0 -4px transparent;
}

.sitemap ul.tree .ex-seo-wrapper h3 {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 15px;
}

.sitemap ul.tree .ex-seo-wrapper .ex-catalog-items {
    margin-top: 4px;
}

.sitemap ul.tree .ex-pagination {
    margin-left: 28px;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 8px;
}

.sitemap ul.tree .ex-pagination li {
    border: 0 none;
    background: none;
}

.up-button{
    position: fixed;
    right: 50px;
    bottom: 50px;
    display: none;
    padding: 1px 7px 3px;
    cursor: pointer;
    background: $gray-300;
}
.specialindex-body .header-menu{
      margin-bottom: 8px;
    }

blockquote{
  padding: 30px 20px 30px 45px;
  width:500px;
  background-color: #DB532B;
  color:white;
  box-shadow: 0 5px 2px rgba(0,0,0,0.1);
  position:relative;
  transition: background-color 0.6s linear;
}

blockquote:after {
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  top: 100%;
  border: solid transparent;
  border-top-color: #DA532B;
  border-left-color:#DA532B;
  border-width: 10px;
  left: 10%;
}
blockquote:before {
           color: #ffffff;
    content: "«";
    display: block;
    font-size: 80px;
    left: 5px;
    position: absolute;
    top: -30px;
}
.top-btn{
  margin-top: 30px;
}
.top-btn-marg{
  margin-top: 7px;
}
.forum-message{
  background:#eee;
  padding-top:10px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom:30px;
}


// flexslider

.flexslider.control-nav {
  margin-top: 0 !important;
}

.flexslider .cut-img-center {
  vertical-align: middle;
  margin-bottom: 0;
}

.slides p, .ex-promotion-plugin-default p, .ex-promotion-plugin-news p {
  padding-left: 0;
}

.cut-img-center {
  background-color: $white;
}

.flex-caption, .cut-img-center {
  margin: 0;
  text-align: center;
}

.flexslider .slides img, .flexslider .cut-img {
  display: inline !important;
  width: auto !important;
  max-width: 100%;
}

.flexslider .slides img {
  height: auto;
  -moz-user-select: none;
}

.flexslider .slides img {
  width: 100%;
  display: block;
}

.img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
}
